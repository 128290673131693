<template>
	<div id="wrap">
		<top page="personalOrder" :showSearchButton="false" />
		<div class="mobile-wrap">
			<div class="mail mainBox">
				<div class="left-box">
					<div class="user">
						<div class="user-title">
							<span class="vip">{{grade === 'normal' ? '普通' : 'VIP'}}</span>
							<span>{{phoneNumbers}}</span>
							<a @click="handleLogout">退出</a>
						</div>
					</div>
					<div class="side-menu">
						<router-link to="/personal/order">我的订单</router-link>
						<router-link to="/personal/video" class="on" v-if="this.vipRoles.indexOf('视频') >= 0">视频</router-link>
						<router-link to="/personal/audio" v-if="this.vipRoles.indexOf('音频') >= 0">音频</router-link>
						<router-link to="/personal/document" v-if="this.vipRoles.indexOf('文档下载') >= 0">文档</router-link>
					</div>
				</div>
				<!-- left-box end -->
				<div class="right-box">
					<div class="video-detail">
						<div class="video">
							<!-- 屏蔽h5默认video标签 -->
							<!-- <video :src="video.vod.filePlayUrl"  controls="controls"></video> -->
							<!-- 阿里云播放器 -->
							<ali-player
								v-if="!!video.vod.filePlayUrl"
								ref="player"
								@play="handleAliplayerPlay($event)"
								@pause="handleAliplayerPause($event)"
								@liveStreamStop="handlelLiveStreamStop($event)"
								:autoplay="true"
								:isLive="aliplayer.options.isLive"
								:rePlay="false"
								showBuffer="true"
								showBarTime="5000"
								width="100%"
								height="540px"
								:source="video.vod.filePlayUrl">
							</ali-player>
						</div>
						<div class="con">
							<h2>{{video.courseName}}</h2>
							<span>{{video.docent}}</span>
						</div>
					</div>
				</div>
				
				<div class="clear"></div>
			</div>
		</div>
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "../components/top.vue";
import bottom from "../components/bottom.vue";
import side from "../components/side.vue";
//阿里云播放组件
import aliplayer from '../components/Aliplayer.vue'
import { mapGetters, mapActions } from "vuex";
import { wxShare } from "@/utils/share.js";
export default {
	page: {
		title: '用户中心-视频'
	},
	computed: {
		...mapGetters(["grade", "phoneNumbers", "vipRoles"])
	},
	data() {
		return {
			video: {
				vod: {
					filePlayUrl: ''
				},
				courseName: '',
				docent: ''
			},
			aliplayer: {
				options: {
                    // source:'//player.alicdn.com/video/aliyunmedia.mp4',
                    isLive: false,   //切换为直播流的时候必填（true-直播状态，false-普通模式，播放器普通视频）
                },
			}
		}
	},
	components: {
		top,
		bottom,
		side,
		"ali-player": aliplayer
	},
	methods: {
		...mapActions(["auth/logout"]),
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL线下活动报名', // 分享标题
                        desc: '名师全视频干货解析，当下全球最新流行的花艺技法与造型',
                        link: 'http://www.c-floral.net/#/about',
                        imgUrl: 'http://www.c-floral.net/images/share/logo-02.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleLogout() {
			this["auth/logout"]()
			.then(() => {
				location.reload();
			})
		},
		handleGetSingle() {
			this.video = {
				vod: {
					filePlayUrl: ''
				},
				courseName: '',
				docent: ''
			}


			this.$api.aliyun.video.getPlayInfo(this.$route.params.id)
			.then(response => {
				if(response.code === '0') {
					this.video = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		//播放
		handleAliplayerPlay(event) {
			// console.log(event);
		},
		//暂停
		handleAliplayerPause(event){
			// console.log(event);
		},
		handlelLiveStreamStop(event){
			// console.log(event);
		}
	},
	mounted() {
		this.handleWxShare()
		this.handleGetSingle()
	}
}
</script>

<style src="../../style/css/my-center.css" scoped></style>
<style scoped>

</style>